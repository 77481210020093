import React from 'react';
import ClientLayout from './Components/ClientLayout.js';
import { ClientAuthProvider } from './Contexts/ClientAuthContext.js';
import useDocumentTitle from '../utils/useDocumentTitle.js';

const ClientApp = (props) => {
	useDocumentTitle('LocumTenens.com Timesheet Portal');
	return (
		<ClientAuthProvider>
			<ClientLayout />
		</ClientAuthProvider>
	);
};

export default ClientApp;
